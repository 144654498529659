import ResourceMonitoringPage from "@/components/pages/resource-monitoring/ResourceMonitoringPage.vue";
import BannerSettingPage from "@/components/pages/company-carousel/BannerSettingPage.vue";

export default [
  {
    path: "resources/overview",
    name: "resource.monitoring.overview",
    component: ResourceMonitoringPage,
  },
  {
    path: "carousels",
    name: "carousels",
    component: BannerSettingPage,
  },
];
