<template>
  <div id="HoverCourseManagemenrList">
    <ul id="menu-hover-list">
      <!-- 1 -->
      <li
        @mouseover="onOver"
        id="dropdown1"
        @mouseleave="onLeave"
        class="hover-menu"
      >
        <b-dropdown
          id="dropdown-1"
          dropright
          no-caret
          ref="dropdown1"
          variant="none"
          size="sm"
          class="hover-menu-company"
        >
          <template v-slot:button-content class="hover-icon-main">
            <b-icon
              icon="building"
              aria-hidden="true"
              class="hover-icon"
            ></b-icon>
          </template>
          <div class="link-title hover-title">
            {{ profile.company.displayName }}
          </div>
          <b-dropdown-item
            v-for="(item, index) in userWorkAtCompanies"
            :key="index"
            :href="`/${item.company.url}`"
            class="hover-menu-item"
          >
            <strong class="m-0 p-0 d-block">
              {{ item.company.displayName }}
            </strong>
            <span class="small">{{ item.fullName }}</span>
          </b-dropdown-item>
        </b-dropdown>
      </li>

      <!-- 2 -->
      <li
        @mouseover="onOver"
        id="dropdown_courseManage"
        @mouseleave="onLeave"
        v-if="
          hasRoles([
            'org/admin',
            'org/instructor',
            'org/hr',
            'org/group_admin',
            'tutor/admin',
            'tutor/instructor',
            'tutor/content_creator',
          ])
        "
        class="hover-menu"
      >
        <b-dropdown
          id="dropdown_courseManage"
          dropright
          no-caret
          ref="dropdown_courseManage"
          variant="none"
          size="sm"
        >
          <template v-slot:button-content class="hover-icon-main">
            <b-icon-book-half class="mr-1 hover-icon"></b-icon-book-half>
          </template>
          <div class="link-title hover-title">คอร์สเรียน</div>

          <b-dropdown-item
            v-for="item in hoverlist['dropdown_courseManage']"
            :key="item.title"
            @click="onGoto(item.to.name)"
            class="hover-menu-item"
            variant="none"
            :class="getCollapseListIsActive(item)"
          >
            {{ item.title }}
          </b-dropdown-item>
        </b-dropdown>
      </li>

      <!-- 3 -->
      <li
        @mouseover="onOver"
        id="dropdown_member"
        @mouseleave="onLeave"
        v-if="
          hasRoles([
            'org/admin',
            'org/hr',
            'org/group_admin',
            'org/audit',
            'tutor/admin',
            'tutor/instructor',
            'org/instructor'
          ])
        "
        class="hover-menu"
      >
        <b-dropdown
          id="dropdown_member"
          dropright
          no-caret
          ref="dropdown_member"
          variant="none"
          size="sm"
        >
          <template v-slot:button-content hover-icon>
            <b-icon-people-fill class="mr-1"></b-icon-people-fill>
          </template>
          <div class="link-title menu_hide hover-title">ผู้เรียน</div>
          <b-dropdown-item
            v-for="item in hoverlist['dropdown_member']"
            :key="item.title"
            @click="onGoto(item.to.name)"
            class="hover-menu-item"
            :class="getCollapseListIsActive(item)"
          >
            {{ item.title }}
          </b-dropdown-item>
        </b-dropdown>
      </li>

      <!-- 4 -->
      <li
        @mouseover="onOver"
        id="dropdown_main_exam"
        @mouseleave="onLeave"
        v-if="
          hasRoles([
            'org/admin',
            'org/hr',
            'org/instructor',
            'org/group_admin',
            'tutor/admin',
            'tutor/instructor',
          ])
        "
        class="hover-menu"
      >
        <b-dropdown
          id="dropdown_main_exam"
          dropright
          no-caret
          ref="dropdown_main_exam"
          variant="none"
          size="sm"
        >
          <template v-slot:button-content>
            <b-icon-file-text-fill class="mr-1"></b-icon-file-text-fill>
          </template>
          <div class="link-title menu_hide hover-title">ข้อสอบ</div>
          <b-dropdown-item
            v-for="item in hoverlist['dropdown_main_exam']"
            :key="item.title"
            @click="onGoto(item.to.name)"
            class="hover-menu-item"
            :class="getCollapseListIsActive(item)"
          >
            {{ item.title }}
          </b-dropdown-item>
        </b-dropdown>
      </li>

      <!-- attendance -->
      <li
        @mouseover="onOver"
        id="dropdown_attendance"
        @mouseleave="onLeave"
        v-if="
          hasRoles([
            'org/admin',
            'org/instructor',
            'tutor/admin',
            'tutor/instructor',
          ]) && comId == 512
        "
        class="hover-menu"
      >
        <b-dropdown
          id="dropdown_attendance"
          dropright
          no-caret
          ref="dropdown_attendance"
          variant="none"
          size="sm"
        >
          <template v-slot:button-content>
            <div>
              <img
                src="@/assets/images/icon/icon_attendance.svg"
                alt="attendance"
                height="22px"
              />
            </div>
          </template>
          <div class="link-title menu_hide hover-title">การเข้าชั้นเรียน</div>
          <b-dropdown-item
            v-for="item in hoverlist['dropdown_attendance']"
            :key="item.title"
            @click="onGoto(item.to.name)"
            class="hover-menu-item"
            :class="getCollapseListIsActive(item)"
          >
            {{ item.title }}
          </b-dropdown-item>
        </b-dropdown>
      </li>

      <!-- purchase -->
      <li
        @mouseover="onOver"
        id="dropdown_purchase"
        @mouseleave="onLeave"
        v-if="
          hasRoles(['tutor/admin', 'tutor/instructor']) &&
            (isAccountingType() || isInsuranceType() || isTutorType())
        "
        class="hover-menu"
      >
        <b-dropdown
          id="dropdown_purchase"
          dropright
          no-caret
          ref="dropdown_purchase"
          variant="none"
          size="sm"
        >
          <template v-slot:button-content>
            <b-icon icon="cash" class="mr-1" />
          </template>
          <div class="link-title menu_hide hover-title">การชำระเงิน</div>
          <b-dropdown-item
            v-for="item in hoverlist['dropdown_purchase']"
            :key="item.title"
            @click="onGoto(item.to.name)"
            class="hover-menu-item"
            :class="getCollapseListIsActive(item)"
          >
            {{ item.title }}
          </b-dropdown-item>
        </b-dropdown>
      </li>

      <!-- 5 -->
      <li
        @mouseover="onOver"
        id="dropdown_analytics"
        @mouseleave="onLeave"
        v-if="
          hasRoles([
            'org/admin',
            'org/instructor',
            'org/hr',
            'org/audit',
            'org/group_admin',
            'tutor/admin',
            'tutor/instructor',
            'tutor/content_creator',
          ]) && !canSpecificUserAccess
        "
        class="hover-menu"
      >
        <b-dropdown
          id="dropdown_analytics"
          dropright
          no-caret
          ref="dropdown_analytics"
          variant="none"
          size="sm"
        >
          <template v-slot:button-content>
            <b-icon-bar-chart-line-fill
              class="mr-1"
            ></b-icon-bar-chart-line-fill>
          </template>
          <div class="link-title menu_hide hover-title">ข้อมูลเชิงลึก</div>
          <b-dropdown-item
            v-for="item in hoverlist['dropdown_analytics']"
            :key="item.title"
            @click="onGoto(item.to.name)"
            class="hover-menu-item"
            :class="getCollapseListIsActive(item)"
          >
            {{ item.title }}
          </b-dropdown-item>
        </b-dropdown>
      </li>

      <!-- ไอคอนรายงาน -->
      <li
        @mouseover="onOver"
        id="dropdown_report"
        @mouseleave="onLeave"
        v-if="
          hasRoles([
            'org/instructor',
            'org/hr',
            'org/audit',
            'org/group_admin',
            'org/admin',
            'tutor/admin',
            'tutor/instructor',
            'tutor/content_creator',
          ]) &&
            (comId == 485 || comId == 519 || comId == 513)
        "
        class="hover-menu"
      >
        <b-dropdown
          id="dropdown_report"
          dropright
          no-caret
          ref="dropdown_report"
          variant="none"
          size="sm"
        >
          <template v-slot:button-content>
            <b-icon-clipboard class="mr-1"></b-icon-clipboard>
          </template>
          <div class="link-title menu_hide hover-title">รายงาน</div>
          <b-dropdown-item
            v-for="item in hoverlist['dropdown_report']"
            :key="item.title"
            @click="onGoto(item.to.name)"
            class="hover-menu-item"
            :class="getCollapseListIsActive(item)"
          >
            {{ item.title }}
          </b-dropdown-item>
        </b-dropdown>
      </li>

      <!-- 6 -->
      <li
        @mouseover="onOver"
        id="dropdown_discuss"
        @mouseleave="onLeave"
        v-if="
          hasRoles([
            'org/admin',
            'org/instructor',
            'org/hr',
            'tutor/admin',
            'tutor/instructor',
          ]) && !isInsuranceType()
        "
        class="hover-menu"
      >
        <b-dropdown
          id="dropdown_discuss"
          dropright
          no-caret
          ref="dropdown_discuss"
          variant="none"
          size="sm"
        >
          <template v-slot:button-content>
            <b-icon-chat-right-text-fill />
          </template>
          <div class="link-title menu_hide hover-title">กระดานถาม-ตอบ</div>
          <b-dropdown-item
            v-for="item in hoverlist['dropdown_discuss']"
            :key="item.title"
            @click="onGoto(item.to.name)"
            class="hover-menu-item"
            :class="getCollapseListIsActive(item)"
          >
            {{ item.title }}
          </b-dropdown-item>
        </b-dropdown>
      </li>

      <!-- 7 -->
      <li
        @mouseover="onOver"
        id="dropdown_live"
        @mouseleave="onLeave"
        v-if="
          isMenuShow('live') &&
            hasRoles([
              'org/admin',
              'tutor/admin',
              'tutor/content_creator',
            ]) &&
            [112, 512, 180, 277, 505, 516].includes(comId)
        "
        class="hover-menu"
      >
        <b-dropdown
          id="dropdown_live"
          dropright
          no-caret
          ref="dropdown_live"
          variant="none"
          size="sm"
        >
          <template v-slot:button-content>
            <b-icon-camera-video-fill />
          </template>
          <div class="link-title menu_hide hover-title">Live</div>
          <b-dropdown-item
            v-for="item in hoverlist['dropdown_live']"
            :key="item.title"
            @click="onGoto(item.to.name)"
            class="hover-menu-item"
            :class="getCollapseListIsActive(item)"
          >
            {{ item.title }}
          </b-dropdown-item>
        </b-dropdown>
      </li>

      <!-- 8 -->
      <li
        @mouseover="onOver"
        id="dropdown_affiliate"
        @mouseleave="onLeave"
        v-if="
          isMenuShow('affiliate') &&
            hasRoles(['tutor/admin', 'tutor/instructor']) &&
            isTutorType() && !canSpecificUserAccess
        "
        class="hover-menu"
      >
        <b-dropdown
          id="dropdown_affiliate"
          dropright
          no-caret
          ref="dropdown_affiliate"
          variant="none"
          size="sm"
        >
          <template v-slot:button-content>
            <b-icon icon="link45deg"></b-icon>
          </template>
          <div class="link-title menu_hide hover-title">Affiliate Program</div>
          <b-dropdown-item
            v-for="item in hoverlist['dropdown_affiliate']"
            :key="item.title"
            @click="onGoto(item.to.name)"
            class="hover-menu-item"
            :class="getCollapseListIsActive(item)"
          >
            {{ item.title }}
          </b-dropdown-item>
        </b-dropdown>
      </li>

      <!-- 9 -->
      <li
        @mouseover="onOver"
        id="dropdown_ect"
        @mouseleave="onLeave"
        v-if="
          isMenuShow('etc') && hasRoles(['org/admin', 'tutor/admin']) && (!isInsuranceType() || comId == 503) && !canSpecificUserAccess
        "
      >
        <b-dropdown
          id="dropdown_ect"
          dropright
          no-caret
          ref="dropdown_ect"
          variant="none"
          size="sm"
        >
          <template v-slot:button-content>
            <b-icon-display />
          </template>
          <p class="link-title menu_hide hover-title">อื่นๆ</p>
          <b-dropdown-item
            v-for="item in hoverlist['dropdown_ect']"
            :key="item.title"
            @click="onGoto(item.to.name)"
            class="hover-menu-item"
            :class="getCollapseListIsActive(item)"
          >
            {{ item.title }}
          </b-dropdown-item>
        </b-dropdown>
      </li>
    </ul>
  </div>
</template>

<script>
import _ from "lodash";
import AuthMixin from "@/mixins/auth-mixin.js";
import CustomUserPermissionMixin from "@/mixins/custom-user-permission-mixin.js";
import { mapGetters } from "vuex";
import authService from "@/services/auth";
import CompanySideBars from "./sidebars/CompanySidebarSwitch";

export default {
  props: { company: Object, profile: Object },
  mixins: [AuthMixin, CustomUserPermissionMixin],
  data() {
    return {
      companyUrl: "",
      comId: this.$store.state.Company.companyInfo.id,
      memberId: JSON.parse(localStorage.getItem("user")).id,
      isActive: true,
      hover: false,
      position: "",
      index: "one",
      pointer: "",
      active: null,
      hoverlist: {
        dropdown_courseManage: [
          {
            title: "จัดการคอร์สเรียน",
            to: { name: "course.management.index" },
          },
        ],
        dropdown_member: [
          {
            title: "จัดการผู้เรียน",
            to: { name: "member.index" },
          },
        ],
        dropdown_main_exam: [
          {
            title: "จัดการข้อสอบ",
            to: {
              name: "exam.build",
            },
            iconClass: "fas fa-book",
          },
          {
            title: "สรุปภาพรวมข้อสอบ",
            to: {
              name: "analytics.exam.overview",
            },
            iconClass: "fas fa-book",
          },
          {
            title: "กระดาษคำตอบ",
            to: {
              name: "analytics-answer-sheet-list",
            },
            iconClass: "fas fa-book",
          },
          {
            title: "อัปโหลดข้อสอบ",
            to: {
              name: "company.exam-management.upload",
            },
            meta: {
              module: "exam-management",
            },
            iconClass: "fas fa-book",
          },
        ],
        dropdown_attendance: [
          {
            title: "สรุปการเข้าชั้นเรียน",
            to: { name: "attendance.overview" },
          },
          {
            title: "รายงานการเข้าเรียนรายบุคคล",
            to: { name: "members.attendance.report" },
          },
        ],
        dropdown_purchase: [],
        dropdown_analytics: [],
        dropdown_report: [],
        dropdown_discuss: [],
        dropdown_live: [
          {
            title: "จัดการ Live",
            to: { name: "live.index" },
          },
        ],
        dropdown_affiliate: [],
        dropdown_ect: [],
      },
    };
  },
  computed: {
    ...mapGetters("Authentication", ["authUser"]),
    userWorkAtCompanies() {
      const roles = this.authUser.roles;
      const profiles = this.authUser.profiles;
      return profiles.filter((profile) => {
        return roles.find((role) => {
          return role.companyId == profile.company.id;
        });
      });
    },
    Ac(hl) {
      return hl.to.name.includes(this.$route.name);
    },
    mainanalyticsListCssObj() {
      return {
        active: this.isActive,
      };
    },
    canReadMemberAnalytic() {
      return this.hasPermissions(["read-member-analytic"]);
    },
    canReadCourseAnalytic() {
      return this.hasPermissions(["read-course-analytic"]);
    },

    canReadRevenueAnalytic() {
      return this.hasPermissions(["read-revenue-analytic"]);
    },

    canReadCertificate() {
      return this.hasPermissions(["read-certificate-report"]);
    },
  },
  created() {
    this.companyUrl = this.$route.params.company;
  },
  mounted() {
    this.checkIsTfac();
    if (this.isOrgInstructor) {
      this.hoverlist["dropdown_member"] = [
        {
          title: "ลงทะเบียนเรียน",
          to: { name: "course.enroll.index" },
        },
      ]
    }
    if (this.isOrgAdmin) {
      this.hoverlist["dropdown_member"].push(
        {
          title: "จัดการผู้เรียน",
          to: { name: "member.index" },
        },
        {
          title: "จัดการกลุ่มผู้เรียน",
          to: { name: "group.index" },
        },
        {
          title: "จัดการผู้ใช้งาน",
          to: { name: "members.role" },
        },
        {
          title: "ลงทะเบียนเรียน",
          to: { name: "course.enroll.index" },
        },
      );
      this.hoverlist["dropdown_courseManage"].push(
        {
          title: "จัดการกลุ่มคอร์ส",
          to: { name: "course-group.management.index" },
        },
      );
    }
    if (this.isOrgAdmin && this.comId != 503) {
      this.hoverlist["dropdown_ect"].push(
        {
          title: "ทรัพยากรในระบบ",
          to: { name: "resource.monitoring.overview" },
        },
      );
    }
    if (!this.isInstructor) {
      this.hoverlist["dropdown_courseManage"].push(
        {
          title: "จัดการหมวดหมู่คอร์ส",
          to: {
            name: "tags.overview",
          },
        },
      )
    }
    if (this.isTutor == true && !this.IsTutorAdmin) {
      this.hoverlist = {
        dropdown_courseManage: [
          {
            title: "จัดการคอร์สเรียน",
            to: { name: "course.management.index" },
          },
        ],
        dropdown_member: [
          {
            title: "จัดการผู้เรียน",
            to: {
              name: "member.index",
            },
          },
          {
            title: "ลงทะเบียนเรียน",
            to: { name: "course.enroll.index" },
          },
        ],
        dropdown_main_exam: [
          {
            title: "สรุปภาพรวมข้อสอบ",
            to: {
              name: "analytics.exam.overview",
            },
            iconClass: "fas fa-book",
          },
          {
            title: "กระดาษคำตอบ",
            to: {
              name: "analytics-answer-sheet-list",
            },
            iconClass: "fas fa-book",
          },
          {
            title: "อัปโหลดข้อสอบ",
            to: {
              name: "company.exam-management.upload",
            },
            meta: {
              module: "exam-management",
            },
            iconClass: "fas fa-book",
          },
        ],
        dropdown_attendance: [
          {
            title: "สรุปการเข้าชั้นเรียน",
            to: { name: "attendance.overview" },
          },
          {
            title: "รายงานการเข้าเรียนรายบุคคล",
            to: { name: "" },
          },
        ],
        dropdown_analytics: [],
        dropdown_live: [
          {
            title: "จัดการ Live",
            to: {
              name: "live.index",
            },
          },
        ],
        dropdown_affiliate: [],
        dropdown_ect: [
          {
            title: "ทรัพยากรในระบบ",
            to: {
              name: "resource.monitoring.overview",
            },
          },
        ],
      };
    } else if (this.IsTutorAdmin) {
      this.hoverlist.dropdown_member = [
        {
          title: "จัดการผู้เรียน",
          to: {
            name: "member.index",
          },
        },
        {
          title: "จัดการกลุ่มผู้เรียน",
          to: { name: "group.index" },
        },
        {
          title: "ลงทะเบียนเรียน",
          to: { name: "course.enroll.index" },
        },
        {
          title: "จัดการผู้ใช้งาน",
          to: { name: "members.role" },
        },
      ];
      this.hoverlist["dropdown_ect"].push(
        {
          title: "จัดการรูปภาพแบนเนอร์",
          to: { name: "carousels" },
        },
      );
    }
    if (
      (this.isAccountingType() ||
        this.isInsuranceType() ||
        this.isTutorType()) &&
      this.IsTutorAdmin
    ) {
      this.hoverlist.dropdown_purchase.push(
        {
          title: "จัดการโปรโมชั่น",
          to: {
            name: "purchase.course.promotion.list",
          },
        },
        {
          title: "รายการแจ้งโอนเงิน",
          to: {
            name: "purchase.course-transfer-money-list",
          },
        },
        {
          title: "จัดการคูปอง",
          to: {
            name: "purchase.course.coupon.list"
          },
        }
      );
    }
    if (
      (this.isAccountingType() ||
        this.isInsuranceType() ||
        this.isTutorType()) &&
      (this.IsTutorAdmin || this.isTutorInstructor)
    ) {
      this.hoverlist.dropdown_purchase.push({
        title: "รายการสั่งซื้อคอร์สเรียน",
        to: {
          name: "purchase.course.order.list",
        },
      });
    }
    if ((this.isTutorType() && this.IsTutorAdmin) || this.comId == 519 && !this.isTutorInstructor) {
      this.hoverlist.dropdown_purchase.push({
        title: "ลงทะเบียนจ่ายเงินสด",
        to: {
          name: "purchase.register.cash",
        },
      });
    }
    if (
      this.isAccountingType() &&
      (this.isTutorInstructor || this.IsTutorAdmin || this.isOrgAdmin) &&
      this.comId != 513
    ) {
      this.hoverlist["dropdown_analytics"].push({
        title: "สรุปภาพรวมสภาบัญชี",
        to: {
          name: "analytics.tfac.overview",
        },
      });
    }
    if (this.isTutorType() || this.isAccountingType()) {
      this.hoverlist["dropdown_report"].push({
        title: "รายงานสรุปการขายรายหลักสูตร",
        to: {
          name: "report.tfac.purchasesummarybycoursereport",
        },
      });
    }
    if (!this.isInsuranceType()) {
      this.hoverlist["dropdown_discuss"].push({
        title: "สรุปภาพรวมคำถาม",
        to: {
          name: "analytics.discuss",
        },
      });
    }
    if (
      (this.comId == 277 || this.comId == 505) &&
      this.isHideTransferMoney == true
    ) {
      this.hoverlist.dropdown_purchase.splice(2, 1);
      this.hoverlist.dropdown_courseManage = []
    }

    if (this.comId == 277 && this.isHideCourseManagement == true) {
      this.hoverlist.dropdown_courseManage.splice(0, 1);
    }

    if (this.canSpecificUserAccess) {
      this.hoverlist.dropdown_courseManage = [
        {
          title: "จัดการคอร์สเรียน",
          to: { name: "course.management.index" },
        },
      ]
      this.hoverlist.dropdown_member = [
        {
          title: "จัดการผู้เรียน",
          to: { name: "member.index" },
        },
      ]
      this.hoverlist.dropdown_purchase = [
        {
          title: "รายการแจ้งโอนเงิน",
          to: {
            name: "purchase.course-transfer-money-list",
          },
        },
        {
          title: "ลงทะเบียนจ่ายเงินสด",
          to: {
            name: "purchase.register.cash",
          },
        }
      ];
      this.hoverlist["dropdown_discuss"] = [
        {
          title: "สรุปภาพรวมคำถาม",
          to: {
            name: "analytics.discuss",
          },
        }
      ];
    }

    this.checkCanAccessAffiliateLink();
    this.checkCanReadMemberAnalytic();
    this.checkCanReadCourseAnalytic();
    this.checkCanReadRevenueAnalytic();
    this.checkCanReadExportDataAnalytic();
    this.checkCanReadCertificate();
    this.checkCanReadCompletedEnrollReport();
    this.checkCanReadReportLearningHistory();
    this.checkCanReadFaceRegistration();
    this.checkCanReadFaceRecognition();
  },
  methods: {
    ...CompanySideBars,
    checkIsTfac() {
      if (this.comId == 485) {
        this.hoverlist["dropdown_report"].push({
          title: "รายงานการรับรู้รายได้",
          to: {
            name: "report.tfac.report",
          },
        });
      }
      if (
        this.isAccountingType() &&
        (this.isTutorInstructor || this.IsTutorAdmin) &&
        this.comId == 513
      ) {
        this.hoverlist["dropdown_analytics"].push({
          title: "สรุปภาพรวม NYC",
          to: {
            name: "analytics.tfac.overview",
          },
        });
      }
      if (this.comId == 485 || this.comId == 519 || this.comId == 513) {
        this.hoverlist["dropdown_report"].push(
          {
            title: "รายงานสรุปจำนวนผู้สมัครเรียนแยกตามหลักสูตร",
            to: {
              name: "report.tfac.enrollsummarybycoursereport",
            },
          },
          {
            title: "รายงานข้อมูลผู้เรียนรายบุคคล",
            to: {
              name: "report.tfac.individualstudentfile",
            },
          }
        );
      }
    },
    checkAuthorizationRoles(roles = []) {
      const companyId = this.$store.state.Company.companyInfo.id;
      const userRoles = this.$store.state.Authentication.authUser.roles
        .filter((el) => el.companyId === companyId)
        .map((el) => el.roleName);
      for (let role of roles) {
        if (userRoles.includes(role)) return true;
      }
      return false;
    },
    onGoto(e) {
      this.$router.push({ name: e });
      this.active = e;
      this.checkActiveRoute();
    },
    onOver(e) {
      let value = e.target.id;
      this.pointer = value;
      this.$refs[value].visible = true;
    },
    onLeave(e) {
      let value = e.target.id;
      this.$refs[value].visible = false;
    },
    checkActiveRoute() {
      const useModuleMetaMatcher = !!this.$route.meta.module;
      this.isActive = _.includes(
        _.map(this.hoverlist, (ech) => {
          if (!!ech.meta && !!ech.meta.module) {
            return ech.meta.module;
          }
          return ech.to.name;
        }),
        useModuleMetaMatcher ? this.$route.meta.module : this.$route.name
      );
    },
    // member
    checkCanReadFaceRegistration() {
      if (this.isMenuShow("member-faceRegistration")) {
        authService
          .hasPermissions(["approve-face-self-registration"])
          .then((result) => {
            if (
              result &&
              (this.isOrgAdmin || this.IsTutorAdmin) &&
              (this.isInsuranceType() || this.isAccountingType())
            ) {
              this.hoverlist["dropdown_member"].push({
                title: "คำขอลงทะเบียนใบหน้า",
                to: { name: "face.verify.overview" },
              });
              this.hoverlist["dropdown_member"].push({
                title: "ตรวจสอบใบหน้าลงทะเบียน",
                to: { name: "face.verify.overview2" },
              });
            }
          });
      }
    },
    checkCanReadFaceRecognition() {
      if (this.isMenuShow("member-faceRecognition")) {
        authService
          .hasPermissions(["approve-face-recognition"])
          .then((result) => {
            if (
              result &&
              (this.isOrgAdmin || this.IsTutorAdmin) &&
              (this.isInsuranceType() || this.isAccountingType()) &&
              this.comId != 519
            )
              this.hoverlist["dropdown_member"].push({
                title: "การตรวจสอบใบหน้า",
                to: { name: "members.face-verification.index" },
              });
          });
      }
    },
    //analytics
    checkCanReadMemberAnalytic() {
      if (this.isMenuShow("memberAnalytic")) {
        authService.hasPermissions(["read-member-analytic"]).then((result) => {
          if (result)
            this.hoverlist["dropdown_analytics"].push({
              title: "ภาพรวมผู้เรียน",
              to: { name: "analytics.user.overview" },
            });
        });
      }
    },
    checkCanReadReportLearningHistory() {
      authService.hasPermissions(["read-member-analytic"]).then((result) => {
        if (result)
          this.hoverlist["dropdown_analytics"].push({
            title: "ประวัติการเข้าเรียน",
            to: {name: "report.learning-history-report"},
          });
      });
    },
    checkCanReadCourseAnalytic() {
      authService.hasPermissions(["read-course-analytic"]).then((result) => {
        if (result)
          this.hoverlist["dropdown_analytics"].push({
            title: "ภาพรวมคอร์ส",
            to: { name: "analytics.course.overview" },
          });
      });
    },
    checkCanReadRevenueAnalytic() {
      if (this.checkAuthorizationRoles(["tutor/admin", "tutor/instructor"])) {
        authService.hasPermissions(["read-revenue-analytic"]).then((result) => {
          if (
            result &&
            (this.isAccountingType() ||
              this.isTutorType() ||
              this.isInsuranceType())
          )
            this.hoverlist["dropdown_analytics"].push({
              title: "ภาพรวมรายได้",
              to: { name: "analytics.revenue.overview" },
            });
        });
      }
    },
    checkCanReadExportDataAnalytic() {
      if (this.isMenuShow("analytics-exportDataAnalytic")) {
        this.hoverlist["dropdown_analytics"].push({
          title: "รายงานการเข้าเรียน",
          to: { name: "export.data" },
        });
      }
    },
    checkCanReadCertificate() {
      authService.hasPermissions(["read-certificate-report"]).then((result) => {
        if (result)
          this.hoverlist["dropdown_analytics"].push({
            title: "ภาพรวมประกาศนียบัตร",
            to: { name: "analytics.certificate.overview" },
          });
      });
    },
    // affiliate
    checkCanAccessAffiliateLink() {
      let permission = true;
      if (permission)
        this.hoverlist["dropdown_affiliate"].push({
          title: "Affiliate Links",
          to: { name: "affiliate.link" },
        });
    },
    checkCanAccessAffiliateRevenue() {
      let permission = true;
      if (permission)
        this.hoverlist["dropdown_affiliate"].push({
          title: "ส่วนแบ่งรายได้",
          to: { name: "affiliate.revenue" },
        });
    },
    // report
    checkCanReadCompletedEnrollReport() {
      if (
        (this.checkAuthorizationRoles([
          "tutor/admin",
          "tutor/instructor",
          "org/admin",
          "org/instructor",
        ]) &&
          this.comId == 519) ||
        this.comId == 513
      ) {
        authService.hasPermissions(["read-revenue-analytic"]).then((result) => {
          if (result)
            this.hoverlist["dropdown_report"].push({
              title: "รายงานการจบคอร์สและสอบผ่าน",
              to: { name: "report.tfac.completedEnrollByDateReport" },
            });
        });
      }
    },
    getCollapseListIsActive(eachSidebarRoute) {
      const useModuleMetaMatcher = !!this.$route.meta.module;
      if (useModuleMetaMatcher) {
        return {
          active:
            !!eachSidebarRoute.meta &&
            !!eachSidebarRoute.meta.module &&
            this.$route.meta.module === eachSidebarRoute.meta.module,
        };
      }
      return {
        active: this.$route.name === eachSidebarRoute.to.name,
      };
    },
  },
};
</script>

<style lang="scss">
.hover-menu {
  margin: 9px;
  padding: 9px 7px !important;
  font-size: 12px;
  border: none !important;
  color: #bbbbbb;
}

.dropdown-menu {
  max-height: 500px;
  overflow-y: auto;
}

.dropdown > button {
  border: none !important;
}
.hover-menu .b-icon {
  color: #495464;
}
.hover-title {
  color: #ffffff;
  background-color: #071a36;
  width: 90%;
  margin: 0 auto;
  padding: 8px 15px;
  border-radius: 3px;
  font-size: 12px;
  text-align: left;
}

.hover-menu-item .dropdown-item.active,
.dropdown-item:active {
  background-color: #e8e8e8 !important;
  color: #343434 !important;
}

.hover-menu-item .dropdown-item.hover,
.dropdown-item:hover {
  background-color: #e8e8e8 !important;
  color: #343434 !important;
}

li.hover-menu-item {
  border-radius: 0px !important;
  font-size: 13px;
  text-align: left !important;
  margin: 0px !important;
  padding: 4px 0 !important;
}

ul.hover-menu-item {
  padding: 10px !important;
}

.hover_menu_click {
  background-color: #bbbfca;
  color: #ffffff;
}

#menu-hover-list li.active a {
  color: #f4f4f2;
  background: #bbbfca;
  padding: 10px 20px;
}
</style>
