<template>
  <div>
    <b-container fluid :class="[`${$options.name}__content`]">
      <b-row align-v="center">
        <b-col>
          <b-container fluid @drop="onDrop">
            <b-row v-if="!previewUrl" :class="css.uploadBtnContainer">
              <b-col>
                <b-form-group
                  :valid-feedback="validFeedback"
                  :invalid-feedback="invalidFeedback"
                  :state="fileBrowserState"
                  :class="`${$options.name}__content__upload-btn__form-group`"
                >
                  <div>
                    <p>ลากไฟล์รูปภาพลงมาที่นี่</p>
                    <p>หรือ</p>
                    <label class="btn btn-secondary">
                      เลือกรูป
                      <b-form-file
                        v-model="file"
                        :class="[
                          `${$options.name}__content__upload-btn__file-input`,
                        ]"
                        accept="image/*"
                        :state="fileBrowserState"
                        @change="onFileChange"
                      >
                      </b-form-file>
                    </label>
                  </div>
                  <p v-if="isFail && !file" class="feedback-error">
                    {{ message }}
                  </p>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-else>
              <b-col class="no-padding">
                <div class="image-preview">
                  <label>
                    <img
                      :src="previewUrl"
                      alt="Image Preview"
                      class="preview-image"
                    />
                    <b-form-file
                      v-model="file"
                      :class="[
                        `${$options.name}__content__upload-btn__file-input hidden-input`,
                      ]"
                      accept="image/*"
                      :state="fileBrowserState"
                      @change="onFileChange"
                    >
                    </b-form-file>
                  </label>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "ImageBannerUpload",
  props: ["imagePath"],
  data() {
    return {
      file: null,
      uploading: false,
      css: {
        uploadBtnContainer: {
          [`${this.$options.name}__content__upload-btn`]: true,
          [`${this.$options.name}__content__upload-btn--dropped`]: false,
        },
      },
      isFail: false,
      message: null,
      upload: false,
      previewUrl: null,
    };
  },
  mounted() {
    this.imagePath ? (this.previewUrl = this.imagePath) : null;
  },
  computed: {
    fileBrowserState() {
      if (!this.file) return null;
      return (
        this.isFileExtensionValid(this.file) &&
        this.isFileMimeTypeValid(this.file) &&
        this.checkFileSize(this.file)
      );
    },
    fileSubmitReady() {
      return !!this.file && !!this.fileBrowserState;
    },
    validFeedback() {
      return !this.file ? "" : `อัพโหลดไฟล์รูปภาพ ${this.file.name}`;
    },
    invalidFeedback() {
      if (!this.file) return "";
      if (!this.isFileExtensionValid(this.file)) {
        return "ไม่รองรับการอัปโหลดไฟล์นี้ \n ใช้ไฟล์ JPEG หรือ PNG เท่านั้น";
      }

      if (!this.isFileMimeTypeValid(this.file)) {
        return "Invalid file type.";
      }

      if (!this.checkFileSize(this.file)) {
        return "ขนาดไฟล์ไม่เกิน 2 MB \n กรุณาเลือกรูปใหม่อีกครั้ง";
      }
      return "";
    },
  },
  methods: {
    async onDrop(event) {
      const files = event.dataTransfer.files;
      if (files.length) {
        this.file = files[0];
        await this.convertToBase64(this.file);
      }
    },
    async onFileChange(event) {
      if (event.target.files.length) {
        this.file = event.target.files[0];
        this.createPreview(this.file);
        await this.convertToBase64(this.file);
      }
    },
    createPreview(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.previewUrl = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    convertToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.base64Image = e.target.result;
          resolve(this.base64Image);
          this.$emit("upload", this.base64Image);
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    },
    isFileExtensionValid(file) {
      const ext = file.name
        .split(".")
        .pop()
        .toLowerCase();
      const allowedExtensions = ["jpg", "jpeg", "png", "webp"];
      return allowedExtensions.includes(ext);
    },
    isFileMimeTypeValid(file) {
      const allowedTypes = ["image/jpeg", "image/png", "image/webp"];
      return allowedTypes.includes(file.type);
    },
    checkFileSize(file) {
      const maxSize = 2 * 1024 * 1024;
      if (file.size < maxSize) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/base/_variables.scss";
label {
  font-size: 12px;
}
.form-group {
  margin-bottom: 0;
}
.ImageBannerUpload {
  &__content {
    padding: 0;
    font-size: 12px;
    &__upload-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border: 2px dashed;
      padding: 10px 0;
      .card-body {
        padding: 0;
      }
      &__file-input {
        z-index: -1;
        opacity: 0;
        width: 0px;
        position: absolute;
      }
      &__form-group {
        text-align: center;
      }
      &--dropped {
        border-color: $csq-base-color;
      }
    }
  }
  .card-header {
    padding: 0 auto;
  }
  .feedback-error {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
  }
  .text-warning {
    color: #027bff !important;
    font-size: 12px;
  }
}
.hidden-input {
  display: none;
}
.image-preview img {
  width: 100%;
  height: auto;
  cursor: pointer;
}
.no-padding {
  padding-left: 0;
  padding-right: 0;
}
</style>
