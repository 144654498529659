<template>
  <div>
    <PageHeader title="จัดการรูปภาพแบนเนอร์" />
    <div class="container">
      <div class="bg-container py-5">
        <b-row>
          <b-col cols="8">
            <b-row>
              <b-col cols="6">
                <h5>รูปภาพแบนเนอร์ปัจจุบัน</h5>
              </b-col>
              <b-col v-if="carousels.length > 0" cols="6">
                <b-form-checkbox
                  v-model="isResponsive"
                  switch
                  style="float: right;"
                >
                  <p>Responsive banner</p>
                </b-form-checkbox>
              </b-col>
            </b-row>
            <br />
            <b-row v-if="carousels.length <= 0">
              <b-col cols="12">
                <div class="bg-no-banner">
                  ไม่มีรูปภาพแบนเนอร์ในขณะนี้ กรุณาเพิ่มรูปภาพแบนเนอร์
                </div>
                <br />
              </b-col>
            </b-row>
            <b-row v-else class="mb-4">
              <b-col cols="12">
                <div class="card card-csq no-shadow px-4 py-4">
                  <draggable
                    tag="div"
                    :list="carousels"
                    :disabled="!enabledDraggable"
                    class="mt-3"
                    ghost-class="ghost"
                    v-bind="dragOptions"
                    :move="checkMove"
                    @start="dragging = true"
                    @end="dragging = false"
                  >
                    <!-- component -->
                    <BannerItem
                      v-for="(carouselBanner, index) in carousels"
                      :key="carouselBanner.carouselOrder"
                      :bannerIndex="index"
                      :item="carouselBanner"
                      :isResponsive="isResponsive"
                      @update="getBannerInfoUpdate"
                      @delete="showDeleteModal"
                    />
                  </draggable>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-button
                  variant="primary"
                  @click="addBanner()"
                  :disabled="carousels.length > 9"
                >
                  <b-icon icon="plus"></b-icon>
                  เพิ่มรูปภาพแบนเนอร์
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <!-- tooltip -->
          <b-col v-if="carousels.length > 0" cols="4" style="margin-top: 55px;">
            <div class="card-info">
              <b-media>
                <template #aside>
                  <b-icon
                    icon="info-circle-fill"
                    style="color: #004085;"
                  ></b-icon>
                </template>
                <ul>
                  <li>
                    ขนาดรูปภาพแบนเนอร์ที่แนะนำบน Desktop <br />
                    คือ 3040x1240px
                  </li>
                  <li>
                    ขนาดรูปภาพแบนเนอร์ที่แนะนำบน Mobile <br />
                    คือ 1280x720px
                  </li>
                  <li>
                    ขนาดไฟล์ไม่เกิน 2 MB/รูป <br />
                    โดยสามารถลดขนาดไฟล์ได้ที่ tinyjpg.com
                  </li>
                  <li>
                    ตั้งวันที่แสดงรูปภาพแบนเนอร์ตลอดไป <br />
                    อย่างน้อย 1 รูป
                  </li>
                </ul>
              </b-media>
            </div>
          </b-col>
        </b-row>
        <b-modal v-model="isLoadingModalShow" centered hide-footer hide-header>
          <div class="text-center">
            <b-spinner
              variant="primary"
              label="Spinning"
              style="width: 4rem; height: 4rem;"
            ></b-spinner>
            <h2>กรุณารอสักครู่</h2>
            <p>เรากำลังบันทึกรูปภาพแบนเนอร์ของคุณ</p>
          </div>
        </b-modal>
        <b-modal v-model="isSuccessModalShow" centered hide-footer hide-header>
          <div class="text-center p-3">
            <b-icon
              icon="check-circle"
              variant="success"
              font-scale="5"
            ></b-icon>
            <h3 class="mt-4">บันทึกและเผยแพร่สำเร็จ</h3>
            <a v-if="compressImageFail > 0" class="text-warning">บีบอัดรูปไม่สำเร็จ {{ compressImageFail }} รูป</a>
            <br />
            <b-button
              class="mt-3"
              variant="primary"
              @click="isSuccessModalShow = false"
            >
              เสร็จสิ้น
            </b-button>
          </div>
        </b-modal>
        <b-modal v-model="isDeleteModalShow" centered hide-footer hide-header>
          <div class="text-center p-3">
            <b-icon
              icon="exclamation-triangle"
              variant="warning"
              font-scale="5"
            ></b-icon>
            <h3 class="mt-4">ยืนยันการลบรูปภาพแบนเนอร์หรือไม่</h3>
            <h6>ต้องการลบรูปภาพแบนเนอร์</h6>
            <h6>"{{ bannerName }}" หรือไม่</h6>
            <p></p>
            <br />
            <b-button
              class="mt-3 mr-2"
              variant="outline-secondary"
              @click="isDeleteModalShow = false"
            >
              ยกเลิก
            </b-button>
            <b-button class="mt-3" variant="primary" @click="deleteCarousel()">
              ยืนยัน
            </b-button>
          </div>
        </b-modal>
        <b-modal v-model="isSaveFailModalShow" centered hide-footer hide-header>
          <div class="text-center p-3">
            <b-icon icon="x-circle" variant="danger" font-scale="5"></b-icon>
            <h3 class="mt-4">บันทึกและเผยแพร่ไม่สำเร็จ</h3>
            <h6 v-html="warningText"></h6>
            <br />
            <b-button
              class="mt-3"
              variant="primary"
              @click="isSaveFailModalShow = false"
            >
              ตกลง
            </b-button>
          </div>
        </b-modal>
        <b-modal v-model="isUnSaveModalShow" centered hide-footer hide-header>
          <div class="text-center p-3">
            <b-icon
              icon="exclamation-triangle"
              variant="warning"
              font-scale="5"
            ></b-icon>
            <h3 class="mt-4">การเปลี่ยนแปลงที่ยังไม่ได้บันทึก</h3>
            <h6>ต้องการบันทึกรูปภาพแบนเนอร์</h6>
            <h6>ก่อนออกจากหน้านี้หรือไม่</h6>
            <p></p>
            <br />
            <b-button
              class="mt-3 mr-2"
              variant="outline-secondary"
              @click="cancelLeave()"
            >
              ยกเลิก
            </b-button>
            <b-button class="mt-3" variant="primary" @click="nextRoute()">
              ปิดโดยไม่บันทึก
            </b-button>
          </div>
        </b-modal>
        <b-modal v-model="isApiErrorModalShow" centered hide-footer hide-header>
          <div class="text-center p-3">
            <b-icon icon="x-circle" variant="danger" font-scale="5"></b-icon>
            <h3 class="mt-4">บันทึกและเผยแพร่ไม่สำเร็จ</h3>
            <h6>{{ errorText }}</h6>
            <br />
            <b-button
              class="mt-3"
              variant="primary"
              @click="isApiErrorModalShow = false"
            >
              ตกลง
            </b-button>
          </div>
        </b-modal>
      </div>
      <div
        v-if="carousels.length > 0"
        class="bottom-toolbar border-top fixed-bottom"
        :class="{ 'sidebar-left-hidden': !sidebarOpened }"
      >
        <b-row>
          <b-col cols="12" class="text-right py-3">
            <b-button
              variant="primary"
              :disabled="!isEditCarousel"
              style="margin-right: 120px;"
              @click="updateBannerCarousel()"
            >
              บันทึกและเผยแพร่
            </b-button>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/layouts/PageHeader.vue";
import BannerItem from "@/components/pages/company-carousel/BannerItem.vue";
import draggable from "csq-vue-multi-draggable";
import api from "@/services/api.js";
import moment from "moment";

export default {
  components: { PageHeader, draggable, BannerItem },
  props: ["sidebarOpened"],
  data() {
    return {
      isResponsive: null,
      carousels: [],
      onEditing: false,
      enabledDraggable: true,
      dragMovedContext: {
        fromIndex: null,
        fromElement: null,
        toIndex: null,
        toElement: null,
      },
      dragging: false,
      isLoadingModalShow: false,
      isSuccessModalShow: false,
      isDeleteModalShow: false,
      isSaveFailModalShow: false,
      isUnSaveModalShow: false,
      isApiErrorModalShow: false,
      bannerName: null,
      deletedIndex: null,
      isEditCarousel: false,
      nextRoute: null,
      baseUrl: process.env.VUE_APP_S1_URL || "https://s1-dev.coursesquare.com/",
      token: localStorage.getItem("token"),
      errorText: "",
      warningText: "",
      isSaved: false,
      compressImageFail: 0
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 100,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  async mounted() {
    await this.getCompanyCarousel();
    this.isResponsive = this.carousels.some(
      (item) => item.carouselResponsiveFlag == 1
    );
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditCarousel && !this.isSaved) {
      this.isUnSaveModalShow = true;
    } else {
      next();
    }
    this.nextRoute = next;
  },
  methods: {
    async getCompanyCarousel() {
      let defaultCarousels;
      await api.get(`v1/carousels`).then((data) => {
        defaultCarousels = data.data.map((item) => {
          return {
            carouselId: item.carou_id,
            carouselName: item.carou_name,
            carouselOrder: item.carou_order,
            carouselImage: item.carou_imagePath
              ? this.baseUrl + item.carou_imagePath
              : null,
            carouselMobileImage: item.carou_mobileImagePath
              ? this.baseUrl +
                item.carou_mobileImagePath +
                "?token=" +
                this.token
              : null,
            carouselLinkUrl: item.carou_linkUrl,
            carouselStartDate: item.carou_startDate,
            carouselEndDate: item.carou_endDate,
            carouselResponsiveFlag: item.carou_responsiveFlag,
          };
        });
        this.carousels = defaultCarousels.filter((item) => {
          return item.carouselEndDate
            ? moment(item.carouselEndDate).format("YYYY-MM-DD") >=
                new Date().toISOString().slice(0, 10)
            : item;
        });
      });
    },
    async updateBannerCarousel() {
      let isInputAllMobileImage = true;
      let hasBannerForever = this.carousels.find(
        (element) => element.activeButton == 'forever'
      );
      
      let isNotInputDate = this.carousels.some(
        (element) =>
          element.activeButton == "specificDate" && !element.carouselEndDate
      );

      if (this.isResponsive) {
        isInputAllMobileImage = this.carousels.every(
          (item) => item.carouselMobileImage != null
        );
      }

      if (!isInputAllMobileImage) {
        this.isSaveFailModalShow = true;
        this.warningText = "กรุณาอัปโหลด Mobile banner ให้ครบทุกรูป";
      } else if (isNotInputDate) {
        this.isSaveFailModalShow = true;
        this.warningText =
          "คุณเลือกแสดงแบนเนอร์เฉพาะวันที่<br />กรุณาเลือกวันที่ที่จะแสดง";
      } else if (hasBannerForever) {
        this.isLoadingModalShow = true;

        await this.converImages();

        await api
          .put(`v1/carousels`, { carousel: this.carousels })
          .then(() => {
            this.isLoadingModalShow = false;
            this.isSuccessModalShow = true;
            this.isSaved = true;
            this.isEditCarousel = false;
            this.getCompanyCarousel();
          })
          .catch((error) => {
            this.isLoadingModalShow = false;
            this.isApiErrorModalShow = true;
            this.errorText = error;
            console.error("Error updating banner carousel:", error);
          });
      } else {
        this.isSaveFailModalShow = true;
        this.warningText =
          "ต้องมีรูปภาพแบนเนอร์อย่างน้อย 1 รูป<br />ที่แสดงผลตลอดไป";
      }
    },
    getBannerInfoUpdate({ name, value, isEdit }, index) {
      this.isEditCarousel = true;
      if (name === "activeButton") {
        this.$set(this.carousels[index], "activeButton", value);
        this.isEditCarousel = isEdit
      }
      if (
        this.carousels[index].activeButton == "specificDate" &&
        name === "date"
      ) {
        if (value.start && value.end) {
          this.$set(
            this.carousels[index],
            "carouselStartDate",
            moment(value.start).format("YYYY-MM-DD")
          );
          this.$set(
            this.carousels[index],
            "carouselEndDate",
            moment(value.end).format("YYYY-MM-DD")
          );
        } else {
          console.error("Invalid date value", value);
        }
      } else if (name === "activeButton" && value == "forever") {
        this.$set(this.carousels[index], "carouselStartDate", null);
        this.$set(this.carousels[index], "carouselEndDate", null);
      } else if (name === "desktop") {
        this.$set(this.carousels[index], "carouselImage", value);
      } else if (name === "mobile") {
        this.$set(this.carousels[index], "carouselMobileImage", value);
        this.$set(this.carousels[index], "carouselResponsiveFlag", 1);
      } else {
        this.$set(this.carousels[index], name, value);
      }

      this.$set(this.carousels[index], "carouselOrder", index);
    },
    async converImages() {
      this.compressImageFail = 0;
      const promises = this.carousels.map(async (element, index) => {
        try {
          this.$set(this.carousels[index], "carouselOrder", index);
          
          let base64Image, base64MobileImage;
          
          if (element.carouselImage.includes(this.baseUrl)) {
            base64Image = await this.convertImageUrlToBase64(element.carouselImage);
          } else {
            base64Image = await this.compressImage(element.carouselImage);
          }
          this.$set(this.carousels[index], "carouselImage", base64Image);

          if (element.carouselMobileImage.includes(this.baseUrl)) {
            base64MobileImage = await this.convertImageUrlToBase64(element.carouselMobileImage);
          } else {
            base64MobileImage = await this.compressImage(element.carouselMobileImage);
          }
          this.$set(this.carousels[index], "carouselMobileImage", base64MobileImage);

        } catch (error) {
          console.error(`Error converting images for carousel at index ${index}:`, error);
        }
      });

      await Promise.all(promises);
    },
    convertImageUrlToBase64(url) {
      return new Promise((resolve, reject) => {
        fetch(url)
          .then((response) => response.blob())
          .then((blob) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(blob);
          })
          .catch((error) =>
            reject("Error converting image to Base64: " + error.message)
          );
      });
    },
    async compressImage(image_base64) {
      return new Promise((resolve) => {
        api
          .post(`v1/carousels/compress`, { image_base64: image_base64 })
          .then(({ data }) => {
            resolve(data.base64_image);
          })
          .catch(() => {
            this.compressImageFail += 1;
            resolve(image_base64);
          });
      })
    },
    addBanner() {
      this.carousels.push({
        carouselName: "",
        carouselOrder: null,
        carouselImage: "",
        carouselMobileImage: null,
        carouselLinkUrl: "",
        carouselStartDate: "",
        carouselEndDate: "",
        carouselResponsiveFlag: 0,
      });
    },
    showDeleteModal(data) {
      this.bannerName = data.name;
      this.deletedIndex = data.index;
      this.isDeleteModalShow = true;
    },
    deleteCarousel() {
      this.isEditCarousel = true;
      this.carousels.splice(this.deletedIndex, 1);
      this.isDeleteModalShow = false;
    },
    cancelLeave() {
      this.isUnSaveModalShow = false;
      this.nextRoute = null;
    },
    checkMove: function(e) {
      this.isEditCarousel = true;
      this.dragMovedContext = {
        fromIndex: e.draggedContext.index,
        fromElement: e.draggedContext.element,
        toIndex: e.relatedContext.index,
        toElement: e.relatedContext.element,
      };
    },
  },
};
</script>

<style scoped>
.bg-no-banner {
  background-color: #fbe4d0;
  border: 1px solid #f8d7b9;
  color: #eb8122;
  padding: 12px;
  border-radius: 0.25rem;
}
.bottom-toolbar {
  padding-left: 250px;
  position: fixed;
  bottom: 0px;
  background-color: #fff;
  z-index: 80;
}
.sidebar-left-hidden {
  padding-left: 0px !important;
}
li {
  font-size: 14px;
  margin-left: 10px;
  list-style-type: disc;
  color: #004085;
}
.card-info {
  background-color: #cce5ff;
  border: 1px solid #b8daff;
  border-radius: 4px;
  padding: 15px;
}
h3 {
  font-weight: 600 !important;
}
</style>
