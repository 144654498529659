<template>
  <div>
    <PageHeader title="จัดการผู้เรียน"></PageHeader>

    <b-container fluid="xl">
      <div class="py-5">
        <div class="inner bg-container">
          <div class="card card-csq no-shadow">
            <div class="card-header border-bottom-0">
              <Tabs />
            </div>
            <div class="card-body pt-4">
              <b-row>
                <b-col
                  v-if="
                    hasRoles([
                      'org/admin',
                      'org/hr',
                      'tutor/moderator',
                      'tutor/admin',
                    ]) &&
                      (!isTutorType() || isAllowedToSpecificCompany()) &&
                      !canSpecificUserAccess
                  "
                  sm="12"
                  md="6"
                  order-md="2"
                  class="text-right mb-2"
                >
                  <b-button
                    :to="{
                      name: 'member.create',
                      params: { company: company.url },
                    }"
                    variant="primary"
                  >
                    <b-icon-plus /> เพิ่มผู้เรียน
                  </b-button>
                </b-col>
                <b-col
                  sm="12"
                  md="6"
                  order-md="1"
                  style="display: inline-block"
                >
                  <SearchBox
                    :title="`พิมพ์ชื่อผู้เรียนที่ต้องการค้นหา`"
                    @typed="getSearchKeyword"
                    style="display: inline-block; width: 50%"
                    class="mr-2"
                  />
                  <div
                    style="
                      display: inline-block;
                      width: 45%;
                      position: relative;
                    "
                  >
                    <b-input-group>
                      <b-form-input
                        size="sm"
                        type="text"
                        :value="formattedDate"
                        placeholder="ค้นหาวันที่สมัครเรียน"
                        disabled
                      />
                      <b-input-group-prepend>
                        <b-button
                          size="sm"
                          variant="primary"
                          @click="showCalendarRange()"
                          ><b-icon icon="calendar-range"></b-icon
                        ></b-button>
                      </b-input-group-prepend>
                    </b-input-group>
                    <DatePicker
                      id="calendar-range"
                      style="
                        display: none;
                        float: right;
                        position: absolute;
                        will-change: transform;
                        left: 0px;
                      "
                      v-model="range"
                      is-range
                    />
                  </div>
                </b-col>
              </b-row>
              <b-table
                :items="items.data"
                :fields="fields"
                :busy="isLoading"
                :sort-by.sync="sort.by"
                :sort-desc.sync="sort.desc"
                sort-icon-left
                hover
                responsive
                class="mt-3 table-members"
              >
                <template
                  v-slot:cell(fullname)="{ item: { id, firstName, lastName } }"
                >
                  <router-link
                    v-if="canManageMember && hasRoles(['org/admin', 'tutor/admin']) && !canSpecificUserAccess"
                    :to="{
                      name: 'member.edit',
                      params: { id: id, company: company.url },
                    }"
                    class="text-csq"
                    ><u>{{ `${firstName} ${lastName}` }}</u></router-link
                  >
                  <span v-else class="text-csq">{{
                    `${firstName} ${lastName}`
                  }}</span>
                </template>
                <template v-slot:cell(groups)="props">
                  <span
                    v-for="(group, index) in props.item.groups"
                    :key="index"
                    class="badge badge-secondary mr-1"
                    >{{ group.name }}</span
                  >
                </template>
                <template v-slot:cell(registerDate)="props">
                  {{ props.item.registerDate | thaiDate }}
                </template>
                <template v-slot:cell(action)="{ item }">
                  <b-dropdown
                    v-if="!isTutor || hasRoles(['tutor/admin'])"
                    variant="outline-primary"
                    text="จัดการ"
                    class="mr-2"
                  >
                    <b-dropdown-item @click="redirectToLearningHistory(item)">
                      <p class="d-flex align-items-center">
                        <b-icon
                          class="mr-2"
                          icon="clock-history"
                          scale="0.7"
                        ></b-icon>
                        ประวัติการเข้าเรียน
                      </p>
                    </b-dropdown-item>
                    <b-dropdown-item @click="redirectToAnalyticsUsers(item)">
                      <p class="d-flex align-items-center">
                        <b-icon
                          class="mr-2"
                          icon="file-earmark-text"
                          scale="0.7"
                        ></b-icon>
                        สรุปการเข้าเรียน
                      </p>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="(hasRoles(['tutor/admin', 'org/admin']) && !isAccountingType()) || comId == 519"
                      @click="
                        addModalExtendEnrollShow = true;
                        selectExtendEnroll(item);
                      "
                    >
                      <p class="d-flex align-items-center">
                        <b-icon
                          class="mr-2"
                          icon="calendar-plus"
                          scale="0.7"
                        ></b-icon>
                        ต่ออายุคอร์สเรียน
                      </p>
                    </b-dropdown-item>
                    <b-dropdown-item
                      :onload="checkDisable(item)"
                      @click="disableMember(item)"
                    >
                      <p class="d-flex align-items-center">
                        <b-icon
                          class="mr-2"
                          :icon="checkicon"
                          scale="0.7"
                        ></b-icon>
                        {{ message }}
                      </p>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="canManageMember && !canSpecificUserAccess"
                      @click="confirmRemoveMember(item)"
                    >
                      <p class="d-flex align-items-center text-danger">
                        <b-icon-trash
                          scale="0.7"
                          class="mt-0 mr-2"
                          aria-hidden="true"
                        />
                        ลบผู้เรียน
                      </p>
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
                <div slot="table-busy">
                  <b-spinner class="align-middle text-csq"></b-spinner>
                </div>
              </b-table>
            </div>
            <div class="card-footer bg-transparent border-0">
              <div class="row mt-3">
                <div class="col-lg-4">
                  <form class="form-inline">
                    <div class="form-group mb-2">
                      <label for="staticEmail2">แสดง</label>
                    </div>
                    <div class="form-group ml-2 mb-2">
                      <select
                        v-model="itemsLimit"
                        class="form-control form-control-sm border-0 bg-light"
                        name
                        @change="changeLimit($event)"
                        id
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                      </select>
                    </div>
                    <div class="form-group ml-2 mb-2">
                      <label for="inputPassword2">
                        /
                        {{ items.meta.total | formatNumber }}
                        รายการ
                      </label>
                    </div>
                  </form>
                </div>
                <div class="col-lg-8">
                  <nav
                    class="float-sm-right"
                    aria-label="Page navigation example"
                  >
                    <pagination
                      :data="items"
                      :limit="3"
                      class="pagination pagination-csq pagination-sm rounded-0"
                      @pagination-change-page="getResults"
                    >
                      <span slot="prev-nav">ก่อนหน้า</span>
                      <span slot="next-nav">ถัดไป</span>
                    </pagination>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /.inner -->
      </div>
    </b-container>
    <div>
      <b-modal
        v-model="addModalExtendEnrollShow"
        title="ต่ออายุคอร์สเรียน "
        size="lg"
        :hide-footer="true"
        ref="modal-enroll"
        centered
      >
        <ExtendEnrollModal
          :mId="memberId"
          :courseOptions="courseOptions"
          @hideModalExtendEnrollModal="hideModalExtendEnrollModal($event)"
        />
      </b-modal>
    </div>
  </div>
</template>

<script>
import PageHeader from "../../layouts/PageHeader.vue";
import SearchBox from "../../shared/SearchBox";
import Pagination from "laravel-vue-pagination";
import Numeral from "numeral";
import api from "@/services/api";
import Tabs from "./shared/Tabs";
import AuthMixin from "@/mixins/auth-mixin.js";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import ExtendEnrollModal from "./ExtendCourseEnrollModal";
import moment from "moment";

export default {
  components: {
    SearchBox,
    Pagination,
    Tabs,
    PageHeader,
    DatePicker,
    ExtendEnrollModal
  },

  filters: {
    formatNumber: function(value) {
      return Numeral(value).format("0,0"); // displaying other groupings/separators is possible, look at the docs
    }
  },
  mixins: [AuthMixin],

  props: ["company"],

  data() {
    return {
      comId: this.$store.state.Company.companyInfo.id,
      addModalExtendEnrollShow: false,
      isLoading: true,
      memberId: null,
      items: {
        checkicon: "",
        message: "",
        variantObject: "",
        data: [],
        links: {
          first: null,
          last: null,
          prev: null,
          next: null
        },
        meta: {
          current_page: 0,
          from: 0,
          last_page: 0,
          path: null,
          per_page: 0,
          to: 0,
          total: 0
        }
      },
      memberGroups: [],
      itemsLimit: 10,
      q: "",
      range: {
        start: "",
        end: ""
      },
      dateRangeSelected: {
        start: null,
        end: null
      },
      courseOptions: [],
      fields: [
        { label: "ชื่อผู้เรียน", key: "fullname", sortable: true },
        {
          label: "ชื่อผู้ใช้",
          key: "username",
          sortable: true
        },
        {
          label: "อีเมล",
          key: "email",
          sortable: true
        },
        {
          label: "กลุ่มเรียน",
          key: "groups",
          sortable: false
        },
        {
          label: "วันที่สมัครเรียน",
          key: "registerDate",
          sortable: true
        },
        {
          label: "",
          key: "action"
        }
      ],
      sort: {
        by: "registerDate",
        desc: true
      }
    };
  },

  computed: {
    companyId() {
      return this.company.id;
    },

    sortOrder() {
      if (this.sort.desc) return "desc";
      return "asc";
    },

    formattedDate() {
      if (this.range.start) {
        let rangeCalendar = document.getElementById("calendar-range");
        if (rangeCalendar.style.display == "inline-block") {
          this.showCalendarRange();
        }
        return (
          moment(this.range.start).format("DD MMM YYYY") +
          " - " +
          moment(this.range.end).format("DD MMM YYYY")
        );
      } else {
        return "";
      }
    }
  },

  watch: {
    sort: {
      async handler() {
        await this.getResults();
      },
      deep: true
    }
  },

  created() {
    this.getResults();
    this.fetchCourseOptions();
  },

  methods: {
    redirectToLearningHistory(item) {
      const route = this.$router.resolve({
        name: "report.learning-history-report.members",
        params: {mId: item.id}
      });
      window.open(route.href, "_blank");
    },
    redirectToAnalyticsUsers(item) {
      const route = this.$router.resolve({
        name: "analytics.user.learning-stats",
        params: { mId: item.id }
      });
      window.open(route.href, "_blank");
    },
    async getResults(page = 1, searchBy = null) {
      this.isLoading = true;
      this.dateRangeSelected.start = null;
      this.dateRangeSelected.end = null;
      if (searchBy == "registerDate") {
        this.dateRangeSelected.start = this.range.start
          ? moment(this.range.start).format("YYYY-MM-DD")
          : null;
        this.dateRangeSelected.end = this.range.end
          ? moment(this.range.end).format("YYYY-MM-DD")
          : null;
        this.q = "";
        this.sort.by = "registerDate";
        this.sortOrder = "desc";
      }
      await api
        .get(
          `/v1/companies/${this.company.id}/members?page=${page}&limit=${this.itemsLimit}&q=${this.q}&sort_by=${this.sort.by}&sort_order=${this.sortOrder}&excepts=roles,profiles&startDate=${this.dateRangeSelected.start}&endDate=${this.dateRangeSelected.end}`
        )
        .then(({ data }) => {
          this.items = data;
          this.isLoading = false;
        });
    },

    checkDisable(item) {
      if (item.disabled == 0) {
        this.message = "เข้าเรียนปกติ";
        this.variantObject = "primary";
        this.checkicon = "check-circle-fill";
      } else {
        this.message = "ระงับการเข้าเรียน";
        this.variantObject = "light";
        this.checkicon = "x-circle";
      }
    },

    async disableMember(item) {
      if (item.disabled == 0) {
        item.disabled = 1;
      } else {
        item.disabled = 0;
      }
      await api.put(
        `/v1/members/` + item.id + `?company=${this.company.id}`,
        item
      );
    },

    showCalendarRange() {
      let rangeCalendar = document.getElementById("calendar-range");
      if (rangeCalendar.style.display == "inline-block") {
        rangeCalendar.style.display = "none";
        this.getResults(1, "registerDate");
      } else {
        rangeCalendar.style.display = "inline-block";
      }
    },

    getSearchKeyword(value) {
      this.q = value;
      this.getResults();
    },

    changeLimit(event) {
      this.itemsLimit = event.target.value;
      this.getResults();
    },
    selectExtendEnroll({ id }) {
      this.addModalExtendEnrollShow = true;
      this.memberId = id;
      this.fetchCourseOptions();
    },
    confirmRemoveMember(member) {
      this.$bvModal
        .msgBoxConfirm(
          `ต้องการลบผู้เรียนชื่อ ${member.firstName} ${member.lastName} ใช่หรือไม่?`,
          {
            title: "ยืนยันการลบผู้เรียน",
            size: "md",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "ลบผู้เรียน",
            cancelTitle: "ยกเลิก",
            cancelVariant: "light",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true
          }
        )
        .then(confirm => {
          if (confirm) this.removeMember(member);
        });
    },
    async removeMember({ id, firstName, lastName }) {
      let title = "ไม่สำเร็จ";
      let message = `ลบผู้เรียน ${firstName} ${lastName} ไม่สำเร็จ`;
      let status = "danger";

      const { data } = await api.delete(`/v1/members/${id}`);

      if (data.status == "success") {
        title = "สำเร็จ";
        message = `ลบผู้เรียน ${firstName} ${lastName} เรียบร้อยแล้ว`;
        status = "success";
      }

      this.$bvToast.toast(message, {
        title: title,
        variant: status,
        solid: true
      });

      this.getResults();
    },
    hideModalExtendEnrollModal() {
      this.$refs["modal-enroll"].hide();
    },
    async fetchCourseOptions() {
      try {
        let res = await api.get(`/v1/members/${this.memberId}/course-enroll`);
        this.courseOptions = [];
        this.courseOptions = res.data.data.map(course => {
          return {
            ...course,
            value: `${course.courseId}`,
            text: course.courseName
          };
        });
        this.courseOptions.unshift({
          value: null,
          text: "เลือกคอร์ส"
        });
      } catch (err) {
        console.log("fetchCourseOptions error!");
        console.log(err.response);
      }
    }
  }
};
</script>

<style scoped>
.table-members{
  overflow-x: unset !important;
}
</style>
