<template>
  <li class="dropdown_menu" :class="mainExamListCssObj">
    <a v-b-toggle.etcCollapse>
      <b-icon-display />

      <span class="link-title menu_hide ml-1">อื่นๆ</span>
      <span class="fa arrow menu_hide"></span>
    </a>
    <b-collapse id="etcCollapse" :visible="false" accordion="accordion">
      <main-collapse-menu
        :collapse-list="collapseList"
        :is-active="!isActive"
      />
    </b-collapse>
  </li>
</template>

<script>
import MainCollapseMenu from "@/components/layouts/sidebars/collapse-menu/MainCollapseMenu.vue";
import _ from "lodash";
import AuthMixin from "@/mixins/auth-mixin.js";

export default {
  components: {
    MainCollapseMenu,
  },
  mixins: [AuthMixin],
  data() {
    return {
      isActive: false,
      collapseList: [],
      comId: this.$store.state.Company.companyInfo.id,
    };
  },
  computed: {
    mainExamListCssObj() {
      return {
        active: this.isActive,
      };
    },
  },
  watch: {},
  mounted() {
    this.collapseListDisplay();
  },
  methods: {
    collapseListDisplay() {
      if (this.isOrgAdmin && this.comId != 503) {
        this.collapseList.push({
          title: "ทรัพยากรในระบบ",
          to: {
            name: "resource.monitoring.overview",
          },
        });
      } 
      if (this.IsTutorAdmin) {
        this.collapseList.push({
          title: "จัดการรูปภาพแบนเนอร์",
          to: {
            name: "carousels",
          },
        });
      }
    },
    checkActiveRoute() {
      const useModuleMetaMatcher = !!this.$route.meta.module;
      this.isActive = _.includes(
        _.map(this.collapseList, (ech) => {
          if (!!ech.meta && !!ech.meta.module) {
            return ech.meta.module;
          }
          return ech.to.name;
        }),
        useModuleMetaMatcher ? this.$route.meta.module : this.$route.name
      );
    },
  },
};
</script>
