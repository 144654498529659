var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.sidebarOpened),expression:"sidebarOpened"}],staticClass:"b-sidebar hidden-sidebar overflow-scroll",attrs:{"no-header":"","shadow":""}},[_c('div',{staticClass:"px-3 py-2"},[_c('div',{staticClass:"d-flex brand"},[_c('img',{staticClass:"img-responsive",attrs:{"src":require("@/assets/images/cs_logo_light.png")}}),_c('b-icon',{staticClass:"navigator",attrs:{"icon":"list"},on:{"click":_vm.openSidebar}})],1),_c('div',{staticClass:"company"},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-1",modifiers:{"collapse-1":true}}],staticClass:"main-btn-company"},[_c('b-icon',{attrs:{"icon":"building","aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.profile.company.displayName)+" "),_c('b-icon',{staticStyle:{"float":"right"},attrs:{"icon":"chevron-expand","aria-hidden":"true"}})],1),_c('b-collapse',{staticClass:"mt-2",attrs:{"id":"collapse-1"}},[_c('div',{staticClass:"scrollable-menu"},_vm._l((_vm.userWorkAtCompanies),function(item,index){return _c('a',{key:index,staticClass:"dropdown-item border-bottom",attrs:{"href":`/${item.company.url}`}},[_c('b-media',[_c('strong',{staticClass:"m-0 p-0 d-block"},[_vm._v(_vm._s(item.company.displayName))]),_c('span',{staticClass:"small"},[_vm._v(_vm._s(item.fullName))])])],1)}),0)])],1),_c('hr'),_c('nav',{staticClass:"mb-3"},[_c('ul',{attrs:{"id":"menu"}},[_c('li',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_c('router-link',{attrs:{"to":{
                name: 'course.management.index',
                params: { company: _vm.companyUrl },
              }}},[_c('i',{staticClass:"fa fa-home",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"link-title menu_hide"},[_vm._v("แดชบอร์ด")])])],1),(
              _vm.hasRoles([
                'org/admin',
                'org/instructor',
                'org/hr',
                'org/group_admin',
                'tutor/admin',
                'tutor/instructor',
                'tutor/content_creator',
              ])
            )?_c('CourseManagementList'):_vm._e(),(
              _vm.hasRoles([
                'org/admin',
                'org/hr',
                'org/group_admin',
                'org/audit',
                'tutor/admin',
                'tutor/instructor',
                'org/instructor'
              ])
            )?_c('MemberManagementList'):_vm._e(),(
              _vm.hasRoles([
                'org/admin',
                'org/hr',
                'org/instructor',
                'org/group_admin',
                'tutor/admin',
                'tutor/instructor',
              ])
            )?_c('MainExamList'):_vm._e(),(
              _vm.hasRoles([
                'org/admin',
                'org/instructor',
                'tutor/admin',
                'tutor/instructor',
              ]) && _vm.comId == 512
            )?_c('AttendanceManagementList'):_vm._e(),(
              _vm.hasRoles(['tutor/admin', 'tutor/instructor']) &&
                (_vm.isAccountingType() || _vm.isInsuranceType() || _vm.isTutorType())
            )?_c('PurchaseList'):_vm._e(),(
              _vm.hasRoles([
                'org/admin',
                'org/instructor',
                'org/hr',
                'org/audit',
                'org/group_admin',
                'tutor/admin',
                'tutor/instructor',
                'tutor/content_creator',
              ]) && !_vm.canSpecificUserAccess
            )?_c('analytics-tab'):_vm._e(),(
              _vm.hasRoles([
                'org/instructor',
                'org/hr',
                'org/audit',
                'org/group_admin',
                'org/admin',
                'tutor/admin',
                'tutor/instructor',
                'tutor/content_creator',
              ]) &&
                (_vm.comId == 485 || _vm.comId == 519 || _vm.comId == 513)
            )?_c('ReportTab'):_vm._e(),(
              _vm.hasRoles([
                'org/admin',
                'org/instructor',
                'org/hr',
                'tutor/admin',
                'tutor/instructor',
              ]) && !_vm.isInsuranceType()
            )?_c('DiscussionList'):_vm._e(),(
              _vm.isMenuShow('live') &&
                _vm.hasRoles([
                  'org/admin',
                  'tutor/admin',
                  'tutor/content_creator',
                ]) &&
                [112, 512, 180, 277, 505, 516].includes(_vm.comId)
            )?_c('LiveManagementList'):_vm._e(),(
              _vm.isMenuShow('affiliate') &&
                _vm.hasRoles([
                  'tutor/admin',
                  'tutor/instructor',
                ]) &&
                _vm.isTutorType() && !_vm.canSpecificUserAccess
            )?_c('affiliate-tab'):_vm._e(),(
              _vm.isMenuShow('etc') &&
                _vm.hasRoles(['org/admin', 'tutor/admin']) &&
                (!_vm.isInsuranceType() || _vm.comId == 503) && !_vm.canSpecificUserAccess
            )?_c('ETCList'):_vm._e()],1)]),_c('hr'),_c('div',[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-2",modifiers:{"collapse-2":true}}],staticClass:"m-1 b-link"},[_c('b-icon-person'),_vm._v(" "+_vm._s(_vm.profile.fullName))],1),_c('b-collapse',{attrs:{"id":"collapse-2"}},[_c('b-dropdown-item',{staticClass:"logout",attrs:{"href":"#"},on:{"click":_vm.logout}},[_c('b-icon-box-arrow-left'),_vm._v(" ออกจากระบบ ")],1)],1)],1)])]),_c('div',{staticClass:"inactive-sidebar"},[_c('ul',[_c('li',{staticClass:"non-effect"},[_c('b-icon',{staticClass:"navigator-d",attrs:{"icon":"list"},on:{"click":_vm.openSidebar}})],1),_c('HoverMenu',{attrs:{"profile":_vm.profile,"company":_vm.company}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }