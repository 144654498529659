<template>
  <li class="dropdown_menu" :class="mainanalyticsListCssObj">
    <a v-b-toggle.analyticsCollapse @click.prevent="toggleCollapse">
      <b-icon-bar-chart-line-fill></b-icon-bar-chart-line-fill>

      <span class="link-title menu_hide ml-1">ข้อมูลเชิงลึก</span>
      <span class="fa arrow menu_hide"></span>
    </a>

    <b-collapse id="analyticsCollapse" :visible="false" accordion="accordion">
      <main-analytics-collapse-menu
        :collapse-list="collapseList"
        :is-active="!isActive"
      />
    </b-collapse>
  </li>
</template>

<script>
import MainAnalyticsCollapseMenu from "@/components/layouts/sidebars/collapse-menu/MainCollapseMenu.vue";
import authService from "@/services/auth";
import CompanySideBars from "../CompanySidebarSwitch";
import _ from "lodash";
import authMixin from "@/mixins/auth-mixin.js";

export default {
  components: {
    MainAnalyticsCollapseMenu
  },
  mixins: [authMixin],
  data() {
    return {
      isActive: false,
      collapseList: [],
      comId: this.$store.state.Company.companyInfo.id
    };
  },
  computed: {
    mainanalyticsListCssObj() {
      return {
        active: this.isActive
      };
    },
    canReadMemberAnalytic() {
      return this.hasPermissions(["read-member-analytic"]);
    },
    canReadCourseAnalytic() {
      return this.hasPermissions(["read-course-analytic"]);
    },

    canReadRevenueAnalytic() {
      return this.hasPermissions(["read-revenue-analytic"]);
    },

    canReadCertificate() {
      return this.hasPermissions(["read-certificate-report"]);
    }
  },
  mounted() {
    this.checkCanReadMemberAnalytic();
    this.checkCanReadCourseAnalytic();
    this.checkCanReadRevenueAnalytic();
    this.checkCanReadExportDataAnalytic();
    this.checkIsTfac();
    this.checkCanReadCertificate();
    this.checkCanReadReportLearningHistory();
  },
  methods: {
    ...CompanySideBars,
    toggleCollapse() {
      // this.isActive = !this.isActive
    },
    checkAuthorizationRoles(roles = []) {
      const companyId = this.$store.state.Company.companyInfo.id;
      const userRoles = this.$store.state.Authentication.authUser.roles
        .filter(el => el.companyId === companyId)
        .map(el => el.roleName);
      for (let role of roles) {
        if (userRoles.includes(role)) return true;
      }
      return false;
    },
    checkActiveRoute() {
      const useModuleMetaMatcher = !!this.$route.meta.module;
      this.isActive = _.includes(
        _.map(this.collapseList, ech => {
          if (!!ech.meta && !!ech.meta.module) {
            return ech.meta.module;
          }
          return ech.to.name;
        }),
        useModuleMetaMatcher ? this.$route.meta.module : this.$route.name
      );
    },
    checkCanReadMemberAnalytic() {
      authService.hasPermissions(["read-member-analytic"]).then(result => {
        if (result)
          this.collapseList.push({
            title: "ภาพรวมผู้เรียน",
            to: {
              name: "analytics.user.overview"
            }
          });
      });
    },
    checkCanReadCourseAnalytic() {
      authService.hasPermissions(["read-course-analytic"]).then(result => {
        if (result)
          this.collapseList.push({
            title: "ภาพรวมคอร์ส",
            to: {
              name: "analytics.course.overview"
            }
          });
      });
    },
    checkCanReadRevenueAnalytic() {
      if (this.checkAuthorizationRoles(["tutor/admin", "tutor/instructor"])) {
        authService.hasPermissions(["read-revenue-analytic"]).then(result => {
          if (result && (this.isAccountingType() || this.isTutorType() || this.isInsuranceType()))
            this.collapseList.push({
              title: "ภาพรวมรายได้",
              to: {
                name: "analytics.revenue.overview"
              }
            });
        });
      }
    },
    checkCanReadCertificate() {
      authService.hasPermissions(["read-certificate-report"]).then(result => {
        if (result)
          this.collapseList.push({
            title: "ภาพรวมประกาศนียบัตร",
            to: {
              name: "analytics.certificate.overview"
            }
          });
      });
    },
    checkIsTfac() {
      if (this.isAccountingType() && (this.isTutorInstructor || this.IsTutorAdmin || this.isOrgAdmin) && this.comId != 513) {
        this.collapseList.push({
          title: "สรุปภาพรวมสภาบัญชี",
          to: {
            name: "analytics.tfac.overview"
          }
        });
      }
      if (this.isAccountingType() && (this.isTutorInstructor || this.IsTutorAdmin) && this.comId == 513) {
        this.collapseList.push({
          title: "สรุปภาพรวม NYC",
          to: {
            name: "analytics.tfac.overview"
          }
        });
      }
    },

    checkCanReadExportDataAnalytic() {
      if (this.isMenuShow("analytics-exportDataAnalytic")) {
        this.collapseList.push({
          title: "รายงานการเข้าเรียน",
          to: {
            name: "export.data"
          }
        });
      }
    },
    checkCanReadReportLearningHistory() {
      authService.hasPermissions(["read-member-analytic"]).then(result => {
        if (result)
          this.collapseList.push({
            title: "ประวัติการเข้าเรียน",
            to: {
              name: "report.learning-history-report"
            }
        });
      });
    },
  }
};
</script>
