<template>
  <div>
    <b-card no-body class="mb-3 no-shadow">
      <b-card-header
        header-tag="header"
        class="bg-white border-bottom-0 pl-1 pr-2"
        role="tab"
      >
        <b-media>
          <template #aside>
            <img
              src="/images/icons/icon_movable.png"
              alt="movable"
              height="20"
              class="align-middle pointer"
            />
          </template>
          <b-row v-if="isResponsive">
            <b-col cols="12">
              <p class="mb-2">Desktop</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <div>
                <DragAndDropImage
                  :imagePath="item.carouselImage"
                  @upload="(base64Image) => uploadImage('desktop', base64Image)"
                />
              </div>
              <br />
              <div v-if="isResponsive">
                <p class="mb-2">Mobile</p>
                <DragAndDropImage
                  :imagePath="item.carouselMobileImage"
                  @upload="(base64Image) => uploadImage('mobile', base64Image)"
                />
              </div>
            </b-col>
            <b-col cols="8">
              <div class="d-flex">
                <b-form-input
                  v-model="bannerName"
                  size="sm"
                  @change="updateBannerInfo($event, 'carouselName')"
                ></b-form-input>
                <b-button
                  class="ml-3"
                  variant="outline-danger"
                  size="sm"
                  @click="
                    $emit('delete', { index: bannerIndex, name: bannerName })
                  "
                >
                  <b-icon icon="trash-fill" variant="danger"></b-icon>
                </b-button>
              </div>
              <div class="my-3 d-flex align-items-center">
                <b-icon
                  font-scale="1"
                  icon="link45deg"
                  variant="primary"
                ></b-icon>
                <b-form-input
                  class="ml-2"
                  v-model="item.carouselLinkUrl"
                  placeholder="URL"
                  size="sm"
                  @change="updateBannerInfo($event, 'carouselLinkUrl')"
                ></b-form-input>
              </div>
              <b-row>
                <b-col cols="5" class="d-flex align-items-center pr-1">
                  <b-icon
                    font-scale="1"
                    icon="stopwatch"
                    variant="primary"
                  ></b-icon>
                  <b-button-group class="mx-2" size="sm" style="width: 100%;">
                    <b-button
                      :variant="
                        activeButton === 'forever'
                          ? 'primary'
                          : 'outline-secondary'
                      "
                      @click="setActiveButton('forever')"
                    >
                      ตลอดไป
                    </b-button>
                    <b-button
                      :variant="
                        activeButton === 'specificDate'
                          ? 'primary'
                          : 'outline-secondary'
                      "
                      @click="setActiveButton('specificDate')"
                    >
                      เฉพาะวันที่
                    </b-button>
                  </b-button-group>
                </b-col>
                <b-col cols="7" class="pl-0">
                  <div
                    v-if="activeButton == 'specificDate'"
                    style="
                      display: inline-block;
                      position: relative;
                    "
                  >
                    <b-input-group>
                      <b-form-input
                        type="text"
                        :value="formattedDate"
                        placeholder="เลือกวันที่แสดงแบนเนอร์"
                        size="sm"
                        disabled
                      />
                      <b-input-group-prepend>
                        <b-button size="sm" @click="showCalendarRange()">
                          <b-icon icon="calendar-event"></b-icon>
                        </b-button>
                      </b-input-group-prepend>
                    </b-input-group>
                    <DatePicker
                      :id="'calendar-range' + bannerIndex"
                      style="
                        display: none;
                        float: right;
                        position: absolute;
                        will-change: transform;
                        left: 0px;
                      "
                      v-model="range"
                      is-range
                      @input="updateBannerInfo($event, 'date')"
                    />
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-media>
      </b-card-header>
    </b-card>
  </div>
</template>

<script>
import DragAndDropImage from "@/components/pages/company-carousel/panel/DragAndDropImage.vue";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import moment from "moment";

export default {
  components: { DragAndDropImage, DatePicker },
  props: ["bannerIndex", "item", "isResponsive"],
  data() {
    return {
      range: {
        start: "",
        end: "",
      },
    };
  },
  mounted() {
    if (this.item.carouselStartDate) {
      this.range.start = this.item.carouselStartDate;
      this.range.end = this.item.carouselEndDate;
    }
    this.updateActiveButton();
  },
  computed: {
    activeButton: {
      get() {
        return this.item.carouselStartDate ? "specificDate" : "forever";
      },
      set() {},
    },
    bannerName: {
      get() {
        if (this.item.carouselName) {
          return this.item.carouselName;
        }
        return "Banner" + (this.bannerIndex + 1);
      },
      set() {},
    },
    formattedDate() {
      if (this.range.start) {
        let rangeCalendar = document.getElementById(
          "calendar-range" + this.bannerIndex
        );
        if (rangeCalendar.style.display == "inline-block") {
          this.showCalendarRange();
        }
        return (
          moment(this.range.start).format("DD/MM/YYYY") +
          " - " +
          moment(this.range.end).format("DD/MM/YYYY")
        );
      } else {
        return "";
      }
    },
  },
  watch: {
    isResponsive() {
      this.updateActiveButton();
    },
  },
  methods: {
    updateActiveButton() {
      if (this.item.carouselStartDate) {
        this.$emit(
          "update",
          { name: "activeButton", value: "specificDate", isEdit: false },
          this.bannerIndex
        );
      } else {
        this.$emit(
          "update",
          { name: "activeButton", value: "forever", isEdit: false },
          this.bannerIndex
        );
      }
    },
    showCalendarRange() {
      let rangeCalendar = document.getElementById(
        "calendar-range" + this.bannerIndex
      );
      if (rangeCalendar.style.display == "inline-block") {
        rangeCalendar.style.display = "none";
      } else {
        rangeCalendar.style.display = "inline-block";
      }
    },
    setActiveButton(button) {
      if (button == "specificDate") {
        this.item.carouselStartDate = new Date().toISOString().slice(0, 10);
      } else {
        this.item.carouselStartDate = null;
      }
      this.activeButton = button;
      this.$emit(
        "update",
        { name: "activeButton", value: button, isEdit: true },
        this.bannerIndex
      );
    },
    updateBannerInfo(value, name) {
      this.$emit("update", { name: name, value: value }, this.bannerIndex);
    },
    uploadImage(deviceType, base64Image) {
      this.$emit(
        "update",
        { name: deviceType, value: base64Image },
        this.bannerIndex
      );
    },
  },
};
</script>

<style scoped>
.vc-container {
  z-index: 1;
}
.input-group-prepend .btn,
.input-group-append .btn {
  z-index: 0;
}
.btn-group > .btn {
  font-size: 13px;
  padding: 0.25rem 0;
}
</style>
